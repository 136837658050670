import { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import { Routes, Route, Link } from "react-router-dom";
import cssScrollSnapPolyfill from "css-scroll-snap-polyfill";

function App() {
  let [tmp, setTmp] = useState("");
  let [modal, setModal] = useState(false);
  let str = "ss";

  return (
    <div className="App">
      <div class="container">
        <header>
          <nav>
            <div className="nav-top">
              <Link to="/" className="logo">
                🔍 exquiz.me{" "}
                <button
                  onClick={() => {
                    axios
                      .get("http://localhost:8081/api/test", {
                        params: { str: "hi" },
                      })
                      .then((result) => {
                        setTmp(result.data.str);
                      })
                      .catch((error) => {
                        alert(error);
                      });
                  }}
                >
                  입력
                </button>
                {tmp}
              </Link>
              <span>
                <img
                  id="menu"
                  src="/images/menu.png"
                  width="30px"
                  onClick={() => {
                    setModal(!modal);
                    document.getElementsById("menu").style.transform =
                      document.getElementsById("menu").style.transform ==
                      "rotate(0deg)"
                        ? "rotate(45deg)"
                        : "rotate(0deg)";
                  }}
                />
              </span>
            </div>
            <div className="nav-bottom">{modal == true ? <Modal /> : null}</div>
          </nav>
        </header>
        <main class="main">
          {/* height:'auto', width:'100%' */}
          <Routes>
            <Route path="/" element={<div>{Home()}</div>} />
            <Route path="/Library" element={<div>{Library()}</div>} />
            <Route path="/Price" element={<div>{Price()}</div>} />
            <Route path="/Company" element={<div>{Company()}</div>} />
            {/* dynamic */}
            <Route path="/Participate" element={<div>{Participate()}</div>} />
            <Route path="/Problem" element={<div>어바웃페이지임</div>} />
            <Route path="/Hosting" element={<div>어바웃페이지임</div>} />
          </Routes>
        </main>

        <footer>
          <Routes>
            {/* static */}
            <Route path="/" element={footerText()} />
            <Route path="/Library" element={footerText()} />
            <Route path="/Price" element={footerText()} />
            <Route path="/Company" element={footerText()} />
            {/* dynamic */}
            <Route path="/Participate" element={<div></div>} />
            <Route path="/Problem" element={<div></div>} />
            <Route path="/Hosting" element={<div></div>} />
          </Routes>
        </footer>
      </div>
    </div>
  );
}

function footerText() {
  return (
    <div style={{ textAlign: "center" }}>
      <hr></hr>
      <p style={{ fontSize: "14px", color: "gray" }}>
        Copyright © 2022 exquiz.me All rights reserved.
      </p>
      <p style={{ fontSize: "14px", color: "gray" }}>Team MUMOMU.</p>
    </div>
  );
}

function Home() {
  return (
    <div className="routeContainer" style={{ height: "550px" }}>
      <div>
        <div className="Intro-Participate">
          <p className="Intro-ParticipateTitle1">퀴즈에 경험을 더하다.</p>
          <p className="Intro-ParticipateTitle2">✏️ 참가하기</p>
          <input
            className="Intro-ParticipateInput"
            type="text"
            placeholder="핀번호를 입력하세요."
            size="10"
          ></input>
          <Link class="Intro-ParticipateButton" to="/Participate">
            🚪
          </Link>
        </div>
        <div className="main-second">
          <Link to="/Hosting" style={{ textDecoration: "none" }}>
            <div
              className="Intro-Hosting"
              style={{
                textAlign: "center",
                width: "90%",
                height: "100px",
                margin: "40px 30px",
                borderRadius: "10px",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
                background: "linear-gradient(to right, #ec6f66, #f3a183)",
              }}
            >
              <p className="Intro-HostingTitle">⛪️ 개최하기</p>
            </div>
          </Link>

          <Link to="/Problem" style={{ textDecoration: "none" }}>
            <div
              className="Intro-Problem"
              style={{
                textAlign: "center",
                width: "90%",
                height: "100px",
                margin: "40px 30px",
                borderRadius: "10px",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
                background: "linear-gradient(to right, #7474bf, #348ac7)",
              }}
            >
              <p className="Intro-ProblemTitle">📄 출제하기</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

function Modal(props) {
  return (
    <div className="modal">
      <p style={{ padding: "8px 0px" }}></p>
      <Link to="/Library" style={{ textDecoration: "none" }}>
        <p
          style={{
            color: "white",
            fontWeight: "700",
            fontSize: "20px",
            padding: "10px 20px",
          }}
          onClick={() => {}}
        >
          라이브러리
        </p>
      </Link>

      <Link to="/Price" style={{ textDecoration: "none" }}>
        <p
          style={{
            color: "white",
            fontWeight: "700",
            fontSize: "20px",
            padding: "10px 20px",
          }}
          onClick={() => {}}
        >
          가격안내
        </p>
      </Link>

      <Link to="/Company" style={{ textDecoration: "none" }}>
        <p
          style={{
            color: "white",
            fontWeight: "700",
            fontSize: "20px",
            padding: "10px 20px",
          }}
          onClick={() => {}}
        >
          회사소개
        </p>
      </Link>
      {/* <Link to="/" style={{textDecoration:'none'}}><p style={{color:'white', fontWeight:'700', fontSize:'20px', padding:'10px 20px'}}>라이브러리</p></Link> */}
    </div>
  );
}

function Library() {
  let array = [
    {
      quizId: 1,
      quizTitle: "고3 6월 모의고사",
      quizText: "고3 실전반 문제집",
      pin: 123456,
    },
    {
      quizId: 2,
      quizTitle: "고3 9월 모의고사",
      quizText: "고3 실전반 문제집",
      pin: 234567,
    },
    {
      quizId: 3,
      quizTitle: "고3 대학수학능력평가",
      quizText: "고3 실전반 문제집",
      pin: 345678,
    },
  ];

  let [info, setInfo] = useState(array);

  return (
    <div style={{ textAlign: "center" }} className="Library">
      <p style={{ fontSize: "30px", fontWeight: "bold", margin: "30px 0px" }}>
        {" "}
        🗳 내 퀴즈{" "}
      </p>
      <hr></hr>
      {info.map(function (a, i) {
        return (
          <div className="list" key={i}>
            <div style={{ margin: "15px 30px" }}>
              <div
                style={{
                  margin: "40px 40px",
                  height: "100px",
                  width: "auto",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
                }}
              >
                <p>
                  <span style={{ textAlign: "right", fontSize: "18px" }}>
                    {info[i].quizTitle}
                  </span>
                  <CloseButton
                    style={{ float: "right" }}
                    onClick={() => {
                      let copy = [...info];
                      copy.splice(i, 1);
                      setInfo(copy);
                    }}
                  >
                    {" "}
                    X
                  </CloseButton>
                  <hr></hr>
                </p>

                {/* <h4 onClick={()=>{setModal(true); setTitle(i)}}> */}
                <span className="star" onClick={() => {}}></span>
                {/* </h4> */}
                <p>{info[i].quizText}</p>
                <p style={{ color: "gray", fontSize: "18px" }}>
                  PIN #{info[i].pin}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Participate() {
  const gra = function (min, max) {
    return Math.random() * (max - min) + min;
  };
  const init = function () {
    let items = document.querySelectorAll("section");
    for (let i = 0; i < items.length; i++) {
      items[i].style.background = "white";
    }
    cssScrollSnapPolyfill();
  };
  init();

  let array = [
    {
      quizTitle: "소프트웨어 마에스트로 연수생 자격평가",
      quizText: "swm 13기 전용 시험",
      quizClosing: "수고하셨습니다",
      problems: [
        {
          problemText: "contribution의 뜻은?",
          problemType: 0,
          answerText1: "파국",
          answerText2: "멸종",
          answerText3: "기여",
          answerText4: "화합",
          answer: 3,
          timeLimit: 15,
        },
        {
          problemText: "객체지향프로그래밍을 영어로 하면",
          problemType: 0,
          answerText1: "CORS",
          answerText2: "SUDO",
          answerText3: "CSS",
          answerText4: "OOP",
          answer: 4,
          timeLimit: 30,
        },
        {
          problemText: "소프트웨어 마에스트로 센터에서 가장 가까운 역은?",
          problemType: 0,
          answerText1: "선릉",
          answerText2: "안양",
          answerText3: "강남",
          answerText4: "역삼",
          answer: 1,
          timeLimit: 10,
        },
      ],
    },
  ];

  let array2 = [
    {
      problemText: "contribution의 뜻은?",
      problemType: 0,
      answerText1: "파국",
      answerText2: "멸종",
      answerText3: "기여",
      answerText4: "화합",
      answer: 3,
      timeLimit: 15,
    },
    {
      problemText: "객체지향프로그래밍을 영어로 하면",
      problemType: 0,
      answerText1: "CORS",
      answerText2: "SUDO",
      answerText3: "CSS",
      answerText4: "OOP",
      answer: 4,
      timeLimit: 30,
    },
    {
      problemText: "소프트웨어 마에스트로 센터에서 가장 가까운 역은?",
      problemType: 0,
      answerText1: "선릉",
      answerText2: "안양",
      answerText3: "강남",
      answerText4: "역삼",
      answer: 1,
      timeLimit: 10,
    },
  ];

  let [info] = useState(array2);

  return (
    <div style={{ height: "100%" }} class="Participate">
      {info.map(function (a, i) {
        return (
          <section className="list" key={i}>
            <div style={{ margin: "15px 30px" }}>
              <div
                style={{
                  margin: "40px 40px",
                  height: "100px",
                  width: "auto",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.3)",
                }}
              >
                <p>
                  <span style={{ textAlign: "center", fontSize: "18px" }}>
                    Q{i + 1}.
                  </span>
                  <hr></hr>
                  <span>{info[i].problemText}</span>
                </p>
              </div>
            </div>

            <div className="answerContainer">
              <div>
                <span onClick={() => {}} className="answer1">
                  {info[i].answerText1}
                </span>
                <span onClick={() => {}} className="answer2">
                  {info[i].answerText2}
                </span>
              </div>
              <div>
                <span onClick={() => {}} className="answer3">
                  {info[i].answerText3}
                </span>
                <span onClick={() => {}} className="answer4">
                  {info[i].answerText4}
                </span>
              </div>
            </div>
          </section>
        );
      })}
      <section>
        <div style={{ fontSize: "40px", color: "black" }}>
          {array[0].quizclosing}수고하셨습니다.
        </div>
      </section>
    </div>
  );
}

function Price() {}

function Company() {}

export default App;
